@import 'colors';

.seed_h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
}

.seed_h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.seed_h4 {
  color: $c5;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.seed_h5 {
  color: $c5;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.seed_h6 {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

.paragraph_normal_regular {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.paragraph_medium_regular {
  color: $c4;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.paragraph_bold_regular {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: $c5;
}

.paragraph_small {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $c4;
}

.paragraph_normal_small {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $c4;
}

.paragraph_bold_small {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: $c5;
}

.text_danger, .text_error {
  color: $c_alert_danger_text;
}
