@import 'colors';

.seed_alert {
  color: $c_alert_info_text;
  background-color: $c_alert_info_bg;
  border-radius: 8px;
  padding: 16px;

  a {
    color: $c_alert_info_text;
    text-decoration: underline;
  }

  line-height: 1.62em;
}
