// Set of reusable colors. See https://seed-ui.vercel.app/colours.

// Using CSS variables to have an option of runtime theme switching.
:root {
  --c1: #FFFFFF;
  --c2: #F6F7FA;
  --c3: #443F51;
  --c4: #6D7386;
  --c4_dark: #B0B4BC;
  --c4_10: rgba(109, 115, 134, 0.1);
  --c4_20: rgba(109, 115, 134, 0.2);
  --c5: #2F2C3A;
  --c6: #FFB200;

  --c_alert_info_text: #3B73DE;
  --c_alert_info_bg: #E6F1FA;
  --c_alert_warning_text: #C78F0F;
  --c_alert_warning_bg: #FFEFCA;
  --c_alert_success_text: #407B24;
  --c_alert_success_bg: #CBEABC;
  --c_alert_danger_text: #C70F0F;
  --c_alert_danger_bg: #FFCACA;

  --click_button_color_primary_background_default: #FCFF74;
  --palette_info_50: #DAE6FC;
  --palette_info_100: #B5CDF9;
  --link_default: #2972E5;
  --link_hover: #1253B8;
  --click_global_color_text_default: #161517;
  --click_feedback_color_neutral_background: #E6E7E9;
  --palette_neutral_700: #414141;
  --palette_neutral_725: #282828;
  --click_alert_color_background_neutral: rgba(230, 231, 233, 1);
  --click_alert_color_iconBackground_neutral: rgba(222, 223, 225, 1);
  --click_alert_color_text_neutral: rgba(83, 87, 95, 1);
  --click_alert_color_icon_neutral: rgba(83, 87, 95, 0.75);
  --click_alert_color_background_success: rgba(224, 248, 231, 1);
  --click_alert_color_iconBackground_success: rgba(216, 240, 223, 1);
  --click_alert_color_text_success: rgba(28, 132, 57, 1);
  --click_alert_color_icon_success: rgba(26, 132, 58, 0.75);
  --click_alert_color_background_warning: rgba(255, 237, 216, 1);
  --click_alert_color_iconBackground_warning: rgba(246, 229, 209, 1);
  --click_alert_color_text_warning: rgba(158, 86, 0, 1);
  --click_alert_color_icon_warning: rgba(22, 21, 23, 1);
  --click_alert_color_background_danger: rgba(255, 221, 221, 1);
  --click_alert_color_iconBackground_danger: rgba(246, 214, 214, 1);
  --click_alert_color_text_danger: rgba(193, 0, 0, 1);
  --click_alert_color_icon_danger: rgba(193, 1, 0, 0.75);
  --click_alert_color_background_info: rgba(218, 230, 252, 1);
  --click_alert_color_iconBackground_info: rgba(211, 222, 244, 1);
  --click_alert_color_text_info: rgba(19, 91, 230, 1);
  --click_alert_color_icon_info: rgba(26, 91, 230, 0.75);
}

$c1: var(--c1);
$c2: var(--c2);
$c3: var(--c3);
$c4: var(--c4);
$c4_dark: var(--c4_dark);
$c4_10: var(--c4_10);
$c4_20: var(--c4_20);
$c5: var(--c5);
$c6: var(--c6);

$c_alert_info_text: var(--c_alert_info_text);
$c_alert_info_bg: var(--c_alert_info_bg);
$c_alert_warning_text: var(--c_alert_warning_text);
$c_alert_warning_bg: var(--c_alert_warning_bg);
$c_alert_success_text: var(--c_alert_success_text);
$c_alert_success_bg: var(--c_alert_success_bg);
$c_alert_danger_text: var(--c_alert_danger_text);
$c_alert_danger_bg: var(--c_alert_danger_bg);

$click_button_color_primary_background_default: var(--click_button_color_primary_background_default);
$palette_info_50: var(--palette_info_50);
$palette_info_100: var(--palette_info_100);
$link_default: var(--link_default);
$link_hover: var(--link_hover);
$click_global_color_text_default: var(--click_global_color_text_default);
$click_feedback_color_neutral_background: var(--click_feedback_color_neutral_background);
$palette_neutral_700: var(--palette_neutral_700);
$palette_neutral_725: var(--palette_neutral_725);

$click_alert_color_background_neutral: var(--click_alert_color_background_neutral);
$click_alert_color_iconBackground_neutral: var(--click_alert_color_iconBackground_neutral);
$click_alert_color_text_neutral: var(--click_alert_color_text_neutral);
$click_alert_color_icon_neutral: var(--click_alert_color_icon_neutral);
$click_alert_color_background_success: var(--click_alert_color_background_success);
$click_alert_color_iconBackground_success: var(--click_alert_color_iconBackground_success);
$click_alert_color_text_success: var(--click_alert_color_text_success);
$click_alert_color_icon_success: var(--click_alert_color_icon_success);
$click_alert_color_background_warning: var(--click_alert_color_background_warning);
$click_alert_color_iconBackground_warning: var(--click_alert_color_iconBackground_warning);
$click_alert_color_text_warning: var(--click_alert_color_text_warning);
$click_alert_color_icon_warning: var(--click_alert_color_icon_warning);
$click_alert_color_background_danger: var(--click_alert_color_background_danger);
$click_alert_color_iconBackground_danger: var(--click_alert_color_iconBackground_danger);
$click_alert_color_text_danger: var(--click_alert_color_text_danger);
$click_alert_color_icon_danger: var(--click_alert_color_icon_danger);
$click_alert_color_background_info: var(--click_alert_color_background_info);
$click_alert_color_iconBackground_info: var(--click_alert_color_iconBackground_info);
$click_alert_color_text_info: var(--click_alert_color_text_info);
$click_alert_color_icon_info: var(--click_alert_color_icon_info);
