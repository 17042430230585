@import 'colors';

.seed_input {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  line-height: 21px;
  min-height: 41px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #E6E6E6;
  transition-property: background-image, background-size, background-color;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: 300ms;
  background-color: #FCFCFC;
  background-size: 0, 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(0deg, #ffb200, #ffb200 2px, transparent 0, transparent);

  &:focus, &.active {
    outline: none;
    background-size: 100%, 100%;
    background-color: #FFFFFF;
  }
}

.seed_form_element {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .seed_label, label {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: $c5;
    margin-bottom: 12px;
    display: block;
  }

  input, textarea {
    @extend .seed_input;
  }

  .seed_input_icon {
    position: absolute;
    top: 27px;
    right: 2px;
    z-index: 10;

  }

  .seed_error {
    font-size: 12px;
    color: #f44336;
    margin-top: 4px;
  }

  input:-webkit-autofill, .seed_input:-webkit-autofill,
  input:-webkit-autofill:hover, .seed_input:-webkit-autofill:hover,
  input:-webkit-autofill:active, .seed_input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #FCFCFC inset !important;
  }

  input:-webkit-autofill:focus, .seed_input:-webkit-autofill:focus {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -2px 0px #FFB200 !important;
  }
}

// Radio buttons style
$radio-button-size: 16px;
mat-radio-button {

  &.mat-radio-checked {
    .mat-radio-label {
      .mat-radio-container {
        .mat-radio-outer-circle {
          background-color: $c6;
          border-color: $c6;
        }

        .mat-radio-inner-circle {
          background-color: $c1;
        }
      }
    }
  }

  // Not checked / base.
  .mat-radio-label {
    .mat-radio-container {
      height: $radio-button-size;
      width: $radio-button-size;

      .mat-radio-outer-circle {
        border-color: $c4_dark;
        border-width: 1px;
        background-color: $c1;
        height: $radio-button-size;
        width: $radio-button-size;
      }

      .mat-radio-inner-circle {
        background-color: $c1;
        height: $radio-button-size;
        width: $radio-button-size;
        transform: scale(0.37);
      }

      .mat-radio-input {
        height: $radio-button-size;
        width: $radio-button-size;
      }

      .mat-ripple .mat-radio-ripple {
        height: $radio-button-size * 2;
        width: $radio-button-size * 2;
        left: calc(50% - $radio-button-size);
        top: calc(50% - $radio-button-size);
      }

      .mat-ripple.mat-radio-ripple {
        .mat-ripple-element {
          background-color: $c6;
        }
      }
    }
  }
}
