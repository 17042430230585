$breakpoints: (
  xl: 'min-width: 1376px',
  desktop: 'max-width: 1375px',
  tablet: 'max-width: 1020px',
  sm: 'min-width: 640px',
  md: 'min-width: 768px',
  mobile: 'max-width: 700px'
);


/**
Example usage:
@include customBreakpointCss('max-width:1200px') {
  font-size:13px;
}
 */
@mixin customBreakpointCss($breakpointNames...) {
  @each $breakpointName in $breakpointNames {
    $cssProperty: null;
    @if (map-has_key($breakpoints, $breakpointName)) {
      $cssProperty: map-get($breakpoints, $breakpointName);
    } @else {
      $cssProperty: $breakpointName;
    }
    @media only screen and ($cssProperty) {
      @content
    }
  }
}

.card {
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  box-shadow: 0 4px 12px rgba(108, 117, 125, 0.12);
  box-sizing: border-box;
  background: #FFFFFF;
  padding: 16px 24px;
}
