/**
  * Common properties for all Highcharts.
  * Contains only used & altered properties.
  * See https://code.highcharts.com/css/highcharts.css for more options or check classes in Browser Dev Tools.
*/

@import "colors";

// Common font charts.
@mixin highchartsFont() {
  font-family: 'Inter', sans-serif;
  font-style: normal;
}

.highcharts-container {

  .highcharts-title {
    @include highchartsFont();
    font-weight: 600;
    font-size: 14px !important;
    line-height: 16px;
    text-align: center;
    fill: $c5 !important;
  }

  .highcharts-axis-labels text {
    @include highchartsFont();
    font-weight: 500;
    font-size: 9px !important;
    line-height: 11px;
    color: $c4 !important;
  }

  .highcharts-grid-line {
    fill: none;
    stroke: $c2;
    stroke-width: 1px;
  }
}
