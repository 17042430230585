// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'node_modules/@angular/material' as mat;
// Plus imports for other components in your app.
@use 'node_modules/@angular/material/theming' as theming;
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat.core();
@import '@angular/material/theming';
@import 'mixins';
@import 'colors';
@import 'typography';
@import 'seed-global';

//noinspection CssInvalidFunction
$custom-typography: mat-typography-config(
  $font-family: 'Inter'
);

//noinspection SassScssResolvedByNameOnly
@include angular-material-typography($custom-typography);

$clickhouse-palette: (
  50: #FFF9E0,
  100: #FFF0B3,
  200: #FFE680,
  300: #FFDB4D,
  400: #FFD426,
  500: #FFB200,
  600: #FFC700,
  700: #FFC000,
  800: #FFB900,
  900: #DB8300,
  A100: #FFFFFF,
  A200: #FFFAF2,
  A400: #FFE8BF,
  A700: #FFDFA6,
  contrast: (
    50: theming.$dark-primary-text,
    100: theming.$dark-primary-text,
    200: theming.$dark-primary-text,
    300: theming.$dark-primary-text,
    400: theming.$dark-primary-text,
    500: theming.$light-primary-text,
    600: theming.$dark-primary-text,
    700: theming.$light-primary-text,
    800: theming.$light-primary-text,
    900: theming.$light-primary-text,
    A100: theming.$dark-primary-text,
    A200: theming.$light-primary-text,
    A400: theming.$light-primary-text,
    A700: theming.$light-primary-text,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$control-plane-web-primary: mat.define-palette($clickhouse-palette);
$control-plane-web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$control-plane-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$control-plane-web-theme: mat.define-light-theme((
  color: (
    primary: $control-plane-web-primary,
    accent: $control-plane-web-accent,
    warn: $control-plane-web-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($control-plane-web-theme);


html, body {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $c5;
}

.cdk-overlay-container {
  .cdk-global-overlay-wrapper {
    align-items: flex-start !important;
    margin-top: 112px;

    .mat-snack-bar-container {
      position: absolute;
      bottom: 140px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }

    @include customBreakpointCss(tablet) {
      margin-top: 32px;
    }

    @include customBreakpointCss(mobile) {
      margin-top: 0;
    }

    @media screen and (max-height: 800px) {
      margin-top: 32px;
    }
  }
}

.container {
  max-width: 2000px;
  margin: 0 auto;

  @include customBreakpointCss(xl, desktop) {
    width: calc(100% - 32px * 2);
    padding: 0 32px;
  }
  @include customBreakpointCss(tablet, mobile) {
    width: calc(100% - 20px * 2);
    padding: 0 20px;
  }
}

.highlightjs_copy_button {
  background: url("/assets/icons/copy.svg") center center no-repeat;
}

.side_padding {
  padding-right: 64px;
  padding-left: 64px;

  @include customBreakpointCss(tablet) {
    padding-right: 32px;
    padding-left: 32px;
  }
}

a {
  text-decoration: none;
  transition: text-decoration 200ms;
  color: $c_alert_warning_text;

  &:hover {
    text-decoration: underline;

    /** Angular creates links with no 'href' attribute and these links have no 'cursor:pointer' style by default. */
    cursor: pointer;
  }
}

.mat-option.mat-selected {
  background: rgba(0, 0, 0, 0.04) !important;
}

.svg_fill svg {
  fill: currentColor;
}

.svg_stroke svg {
  stroke: currentColor;
}

.inner_page_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 42px;
  padding-bottom: 24px;
  border-bottom: 1px solid $c4_10;

  > * {
    min-height: 34px;
  }
}

.inner_page_title {
  font-size: 28px;
  line-height: 23px;
  font-weight: bold;
  display: flex;
  align-items: center;
}


.instance_menu, .connect_menu {
  &.with_padding {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .menu_buttons {
    .menu_hairline {
      border: 1px solid $c4-10;
      margin: 8px 0;
    }

    .menu_header {
      @extend .seed_h6;
      color: $c4;
      padding: 8.5px 12px;
      text-align: end;
    }

    .menu_button {
      justify-content: flex-end;
    }

    [disabled] {
      opacity: 0.5;
    }

  }

  button, a {
    font-weight: 600;
    text-decoration: none;

    &.delete {
      color: #F5222D;
    }
  }
}

.connect_menu {
  margin-top: 12px;

  .menu_buttons .menu_button {
    text-decoration: none;
    font-weight: 500;
    text-align: right;
  }
}

.mat-simple-snackbar-action > button {
  color: #FFCC00
}

.mat-menu-item .mat-icon {
  margin-right: 5px !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-menu-content {
  padding: 0 !important;
}

.mat-menu-panel {
  min-height: auto !important;
  border-radius: 8px !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  font-weight: bold;
  color: #DB8300;
}

.mat-form-field-label-wrapper {
  top: -1.2em;
}

.mat-dialog-container {
  position: relative;
  border-radius: 8px !important;
  color: $c5;
}

.iframe_modal {
  .mat-dialog-container {
    padding: 0;
    overflow: hidden;
  }
}

// Changed 'overflow' to 'visible' here to select box can break out of the modal
.modal_no_padding {
  .mat-dialog-container {
    padding: 0;
    overflow: visible;

    .mat-dialog-content {
      overflow: visible;
    }
  }
}

.iframe_modal {
  .mat-dialog-container {
    background: transparent !important;
  }
}

.mat-dialog-content {
  max-height: 85vh !important;

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #FFFFFF !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.change_password_modal {
  @extend .modal;

  .mat-dialog-content, .mat-dialog-container {
    overflow: initial !important;
  }
}

.modal {
  .mat-dialog-container {
    padding: 24px 32px !important;
  }
}

.popup_modal {
  .mat-dialog-container {
    padding: 0 !important;
  }
}

.mat-flat-button .mat-button-wrapper {
  display: flex !important;
  justify-content: center;
  align-items: center;

  .icon {
    margin-right: 5px;
  }
}

.monospace {
  font-family: monospace, sans-serif;
}

.profile_menu {
  margin-top: 12px;
  min-width: 197px !important;
  box-sizing: border-box;
}

.mat-tab-label {
  opacity: 1 !important;
}

.mat-tab-label-content {
  color: $c4;

  &:hover {
    color: $c5;
  }
}

.mat-tab-label-active {
  .mat-tab-label-content {
    color: $c5;
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.mat-button-base {
  border-radius: 8px !important;
}

h2, .mat-h3, .mat-subheading-2, .mat-typography h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: $c5;
}

h3, .mat-h3, .mat-subheading-2, .mat-typography h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
  color: $c5;
}


.mat-button-base {
  transition: filter 300ms;

  &:hover:not(.mat-button-disabled) {
    filter: brightness(95%);
  }
}

.menu_buttons {
  .menu_button {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    padding: 0 12px;
    color: $c5;
    height: 34px;
    display: flex;
    align-items: center;
  }
}

.button_loader_icon {
  width: 14px !important;
  height: 14px !important;
  line-height: 0 !important;
}

.relative {
  position: relative;
}

.button_loader_icon_absolute {
  @extend .button_loader_icon;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
}

.error_link {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}

:root {
  --control-plane-text-color-tooltip: white;
  --control-plane-bg-color-tooltip: #322F39;
}

.mat-tooltip {
  font-size: 12px;
  font-weight: 600;
  color: var(--control-plane-text-color-tooltip);
  background: var(--control-plane-bg-color-tooltip);
  border-radius: 6px;
  opacity: 0.7;

  // The code below is needed to make Angular tooltip follow line breaks in the tooltip text.
  // The rendered tooltip will auto-size up to 500px and ellipse if a line in the tooltip exceeds this width.
  white-space: pre;
  max-width: 500px !important;

  &.column_info_icon_tooltip {
    white-space: pre-wrap;
  }

}

input {
  min-height: 18px;
}

.mat-select-value {
  overflow: visible !important;
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: none !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.progress_container {
  margin-top: 24px;
  display: flex;
  gap: 13px;

  .progress_dot {
    width: 11px;
    height: 11px;
    background: #6D7386;
    opacity: 0.3;
    border-radius: 110px;

    &.active {
      background: $c6;
      opacity: 1;
    }
  }
}

.recaptcha-error-container {
  margin-top: 12px;
  max-width: 517px;
}

.recaptcha-error-container > div.recaptcha-error {
  color: $c5;
  background-color: #FFCACA;
  border: solid 1px #C70F0F;
  border-radius: 8px;
  padding: 10px;
}


.expand_or_collapse {
  display: inline;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $c6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

cw-auth-logo[type="full"] {
  margin-bottom: 48px;
}
